import React, { useState, useEffect, useRef } from 'react'
import Sidebar from '../Dashboard/Sidebar'
import Topbar from '../Dashboard/Topbar'
import { API } from '../../API/Api';
import "../Dashboard/Dashboard.css"

function Contract_Address() {
    let UserData = localStorage.getItem("userData");
    let uid = JSON.parse(UserData);
    uid = uid.outuid;

    const CopyAddressRef = useRef(null);
    const CopyAddressHandler = () => {
        const text = CopyAddressRef.current.value;
        navigator.clipboard.writeText(text);
    };

    const [dash, setdataArray] = useState([]);

    const Dashboard_API = async () => {
        try {
            let responce = await API?.get(`GetDashboardDetails?id=${uid}`);
            responce = responce.data.data;
            console.log("responceresponce", responce[0]);
            setdataArray(responce[0]);
        } catch (error) {
            console.log("Error While calling Referrer API", error);
        }
    };

    useEffect(() => {
        Dashboard_API();
    }, [])

    return (
        <div>

            <>
                <Sidebar />
                <Topbar />
                <div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-8" style={{ top: "10rem" }}>
                            <div className=" card-header d-flex justify-content-between">
                                <div className="wdg-links  mod_width">
                                    <div className="wdg-label">Contract Address :</div>
                                    <div className="copy_btn_set"style={{display:"flex"}}>
                                        {/* <div className="wdg-box bxset primary"> */}
                                        <h5 class="mt-5"> <a href="https://bscscan.com/address/0x001A61b0a3D3fd2A70d87612b558184b683959a8" class="Txn_here" target="_blank">0x001A61b0a3D3fd2A70d87612b558184b683959a8</a> 
             </h5>
                                            {/* <input
                                                type="text"
                                                className="wallet_input"
                                                id="myInput"
                                                readOnly=""
                                                ref={CopyAddressRef}
                                                value={`${dash.btcaddress}`}
                                                style={{ fontSize: "2rem" }}
                                                width="100"
                                            /> */}
                                            {/* <div className="fast-msg-box" /> */}
                                        {/* </div> */}
                                        {/* <div className="wdg-actions copy_btn_set2">
                                            <button type="button" onClick="myFunction()">
                                            <button className='btn btn-success' type="button" onClick={CopyAddressHandler}>
                                                <span
                                                    style={{ fontSize: 15 }}
                                                // onClick="myFunction()"
                                                >
                                                    Copy
                                                </span>
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </>

        </div>
    )
}

export default Contract_Address