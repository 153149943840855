import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import clicko from "../../Assets/images/click.png";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom/dist";
import axios from "axios";
import { loadWeb3 } from "../../API/contract";
import { toast } from "react-toastify";
import { API, APILocal } from "../../API/Api";
import {
  MainAddress,
  Main_Abi,
  TokenAddress,
  Token_Abi,
  USDTAddress,
  USDT_Abi,
  Address_5050,
  Abi_5050,
} from "../../API/ContractAddress";

let count1 = 0;
let count2 = 0;
let count3 = 0;
let count4 = 0;
let count5 = 0;
let count6 = 0;
let count7 = 0;
let count8 = 0;
let count9 = 0;
let count10 = 0;
let sum = 0;

export default function Active5050() {
  const [UsdAmount, setUsdAmount] = useState("0");
  const [TokenAmount, setTokenAmount] = useState("0");
  const [Bnb, setBnb] = useState("0");
  let [liveRate, setliveRate] = useState(0);
  let [amount, setamount] = useState(0);
  let [meticmultiplyablevalue, setmeticmultiplyablevalue] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [valueBNB, setvalueBNB] = useState(0);
  let [valueCoin, setvalueCoin] = useState(0);
  let [balanceUle, setbalanceUle] = useState(0);
  let [TrxBalance, setTrxBalance] = useState(0);
  let [spiner, setspiner] = useState(false);
  let [Sum, setSum] = useState(0);
  let [USDTBalance, setUSDTBalance] = useState(0);

  const [dash, setdataArray] = useState([]);

  const [loading, setloading] = useState(false);
  let navigation = useNavigate();

  let UserData = localStorage.getItem("userData");
  let uid = JSON.parse(UserData);
  uid = uid.outuid;

  const Dashboard_API = async () => {
    try {
      let responce = await API?.get(`GetDashboardDetails?id=${uid}`);
      responce = responce.data.data;
      console.log("responceresponce", responce[0]);
      setdataArray(responce[0]);
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();
      console.log("acc", acc);
      if (acc == "No Wallet") {
        // toast.error("No Wallet");
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        //toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wallet is locked");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const TokenBalance = async () => {
    let acc = await loadWeb3();

    let contract = await new window.web3.eth.Contract(Token_Abi, TokenAddress);
    let result = await contract.methods.balanceOf(acc).call();
    result = window.web3.utils.fromWei(result);
    result = parseFloat(result).toFixed(4);
    setbalanceUle(result);
    // console.log("Balance Token", result);

    let contract_USDT = await new window.web3.eth.Contract(
      USDT_Abi,
      USDTAddress
    );
    let result_USDT = await contract_USDT.methods.balanceOf(acc).call();
    result_USDT = window.web3.utils.fromWei(result_USDT);
    result_USDT = parseFloat(result_USDT).toFixed(4);
    setUSDTBalance(result_USDT);
    // console.log("Balance USDT", result_USDT);

    let bNB_balance = await window.web3.eth.getBalance(acc);
    bNB_balance = window.web3.utils.fromWei(bNB_balance);
    bNB_balance = parseFloat(bNB_balance).toFixed(7);
    console.log("Balance_BNB", bNB_balance);
    setTrxBalance(bNB_balance);
  };

  const getLiveRate = async () => {
    try {
      setloading(true);
      let res = await API.get("live_rate");
      console.log("Res", res.data.data[0].usdperunit);
      setliveRate(res.data.data[0].usdperunit);
      setloading(false);
    } catch (e) {
      console.log("Erroe while calling LiveRate Api ", e);
      // setloading(false)
    }
  };

  const getValue_BNB = async () => {
    try {
      let Res = await API.get(`/getconvertValue?id=${amount}`);
      console.log("RES_BNB", Res);
      setvalueBNB(Res.data.data[0].ethereum);
      setvalueCoin(parseFloat(Res.data.data[0].Token) / 2);
    } catch (e) {
      console.log("Error While Calling GetValue API", e);
    }
  };

  const Getprice = async () => {
    try {
      let BNB_rate = 0;
      let Token_rate = 0;
      let usdvalue = amount;

      if (usdvalue > 0) {
        let contract = new window.web3.eth.Contract(Abi_5050, Address_5050);
        return new Promise(async (resolve, reject) => {
          let data = await contract.methods
            .getPrice(usdvalue.toString())
            .call();

          // console.log("getprice()data", data);
          Token_rate = data[1] / 1000000000000000000;
          BNB_rate = data[0] / 1000000000000000000;

          Token_rate = parseFloat(Token_rate);

          console.log("getprice()data[1] Token_rate", Token_rate);
          console.log("getprice()data[0] BNB_rate", BNB_rate);

          setvalueBNB(BNB_rate);
          setvalueCoin(Token_rate);
        });
      } else {
        setvalueBNB(0);
        setvalueCoin(0);
      }
    } catch (e) {
      console.log("Error While Calling GetValue API", e);
    }
  };

  const fifty = async (value) => {
    if (value == 100) {
      setmeticmultiplyablevalue(value);
      // setamount("");
      count2 = 0;
      count3 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      count1 = count1 + 1;

      sum = sum + value;
      setamount(sum);
    } else if (value == 200) {
      setmeticmultiplyablevalue(value);
      // setamount("");
      count1 = 0;
      count3 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      count2 = count2 + 1;

      sum = sum + value;
      setamount(sum);
      //  setamount(value * count2);
    } else if (value == 500) {
      setmeticmultiplyablevalue(value);
      // setamount("");
      count1 = 0;
      count2 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      count3 = count3 + 1;

      sum = sum + value;
      setamount(sum);
    } else if (value == 1000) {
      count1 = 0;
      count2 = 0;
      count3 = 0;
      count5 = 0;
      count6 = 0;
      setmeticmultiplyablevalue(value);
      // setamount("");
      count4 = count4 + 1;

      sum = sum + value;
      setamount(sum);
    } else if (value == 2500) {
      count1 = 0;
      count2 = 0;
      count3 = 0;
      count4 = 0;
      count6 = 0;
      setmeticmultiplyablevalue(value);
      // setamount("");
      count5 = count5 + 1;

      sum = sum + value;
      setamount(sum);
    } else if (value == 5000) {
      // setamount("");
      setmeticmultiplyablevalue(value);
      count1 = 0;
      count2 = 0;
      count3 = 0;
      count4 = 0;
      count5 = 0;
      count6 = count6 + 1;

      sum = sum + value;
      setamount(sum);
    } else if (value == 10000) {
      // setamount("");
      setmeticmultiplyablevalue(value);
      count1 = 0;
      count2 = 0;
      count3 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      count7 = count7 + 1;
      sum = sum + value;
      setamount(sum);
    } else if (value == 15000) {
      // setamount("");
      setmeticmultiplyablevalue(value);
      count1 = 0;
      count2 = 0;
      count3 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      count7 = 0;
      count8 = count8 + 1;
      sum = sum + value;
      setamount(sum);
    } else if (value == 20000) {
      // setamount("");
      setmeticmultiplyablevalue(value);
      count1 = 0;
      count2 = 0;
      count3 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      count7 = 0;
      count8 = 0;
      count9 = count9 + 1;

      sum = sum + value;
      setamount(sum);
    } else if (value == 25000) {
      // setamount("");
      setmeticmultiplyablevalue(value);
      count1 = 0;
      count2 = 0;
      count3 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      count7 = 0;
      count8 = 0;
      count9 = 0;
      count10 = count10 + 1;

      sum = sum + value;
      setamount(sum);
    } else if (value == 0) {
      // setamount("");
      setmeticmultiplyablevalue(value);
      count1 = 0;
      count3 = 0;
      count2 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      sum = 0;
      setamount(sum);
      setvalueCoin(sum);
    } else {
      // setamount("");
      setmeticmultiplyablevalue(value);
      count1 = 0;
      count3 = 0;
      count2 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      sum = 0;
      setamount(sum);
      setvalueCoin(sum);
    }

    // setamount(value * count);
  };

  const Activation = async () => {
    let acc = await loadWeb3();
    if (acc == "No Wallet") {
      toast.error("No Wallet");
    } else if (acc == "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect BSC Scan Network");
    } else if (acc != dash.address) {
      toast.error("Wallet address and login miss match");
    } else if (balanceUle < valueCoin) {
      toast.error("Insufficiant Token Balance");
    } else if (amount == 0) {
      toast.error("Please Select package");
    } else {
      setspiner(true);
      console.log("Data", uid, amount, valueCoin, valueBNB, acc);
      try {

        let res_Cond = await API.post(`/ActivateUpgradeCondition`, {
          uid: uid,
          amount: amount,
          type: "Activate"
        });

        console.log("ActivateUpgradeCondition", res_Cond);
        if (res_Cond.data.data != "Success") {
          toast.error(`${res_Cond.data.data}`);
          setspiner(true);
          return;
        }

        let TokenValue = parseFloat(valueCoin) + 5;
        TokenValue = window.web3.utils.toWei(TokenValue.toString());
        let BNBValue = parseFloat(valueBNB) + 0.001;
        BNBValue = window.web3.utils.toWei(BNBValue.toString());
        let contract = await new window.web3.eth.Contract(
          Abi_5050,
          Address_5050
        );
        let token = await new window.web3.eth.Contract(Token_Abi, TokenAddress);
        const approveBlock = window.web3.eth.getBlock("latest");
        let approveCall = await token.methods
          .approve(Address_5050, TokenValue)
          .send({
            from: acc,
            gasLimit: approveBlock.gasLimit,
            gasPrice: await window.web3.eth.getGasPrice(),
          });
        toast.success("Approved");
        let sellCall = await contract.methods.buyRouter(amount).send({
          from: acc,
          value: BNBValue, // select the bnb
          gasLimit: approveBlock.gasLimit,
          gasPrice: await window.web3.eth.getGasPrice(),
        });
        toast.success("Transaction Succesfull");
        sellCall = sellCall.transactionHash;

        let res = await API.post(`/activation5050`, {
          uid: uid,
          package: "1",
          amount: amount,
          tokenamount: valueCoin,
          trxvalue: valueBNB,
          type: "Activate",
          useraddress: acc,
          transaction: sellCall,
        });

        console.log("Activation", res);
        if (res.data.data == "waiting") {
          toast.success("Activation is under process...");
        } else {
          toast.error(`${res.data.data}`);
        }

        setspiner(false);
      } catch (err) {
        console.log("error while calling fuction Activation", err);
        setspiner(false);
      }
    }
  };

  const Upgrade = async () => {
    let acc = await loadWeb3();
    if (acc == "No Wallet") {
      toast.error("No Wallet");
    } else if (acc == "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect BSC Scan Network");
    } else if (acc != dash.address) {
      toast.error("Wallet address and login miss match");
    } else if (dash.packageamount > amount) {
      toast.error("Please Select Same or higher Package!!!");
    }  else if (amount == 0) {
      toast.error("Please Select package");
    }  else if (balanceUle < valueCoin) {
      toast.error("Insufficiant Token Balance");
    } else {
      setspiner(true);
      console.log("Data", uid, amount, valueCoin, valueBNB, acc);

      try {
        let res_Cond = await API.post(`/ActivateUpgradeCondition`, {
          uid: uid,
          amount: amount,
          type: "Upgrade"
        });

        console.log("ActivateUpgradeCondition", res_Cond);

        if (res_Cond.data.data != "Success") {
          toast.error(`${res_Cond.data.data}`);
          setspiner(false);
          return;
        }

        let TokenValue = parseFloat(valueCoin) + 5;
        TokenValue = window.web3.utils.toWei(TokenValue.toString());
        let BNBValue = parseFloat(valueBNB) + 0.001;
        BNBValue = window.web3.utils.toWei(BNBValue.toString());
        let contract = await new window.web3.eth.Contract(
          Abi_5050,
          Address_5050
        );
        let token = await new window.web3.eth.Contract(Token_Abi, TokenAddress);
        const approveBlock = window.web3.eth.getBlock("latest");
        let approveCall = await token.methods
          .approve(Address_5050, TokenValue)
          .send({
            from: acc,
            gasLimit: approveBlock.gasLimit,
            gasPrice: await window.web3.eth.getGasPrice(),
          });
        toast.success("Approved");
        let sellCall = await contract.methods.buyRouter(amount).send({
          from: acc,
          value: BNBValue, // select the bnb
          gasLimit: approveBlock.gasLimit,
          gasPrice: await window.web3.eth.getGasPrice(),
        });
        toast.success("Transaction Succesfull");
        sellCall = sellCall.transactionHash;

        let res = await API.post(`/upgrade5050`, {
          uid: uid,
          amount: amount,
          tokenamount: valueCoin,
          trxvalue: valueBNB,
          type: "Upgrade",
          useraddress: acc,
          transaction: sellCall,
        });

        console.log("Upgrade", res);
        if (res.data.data == "waiting") {
          toast.success("Upgrade is under process...");
        } else {
          toast.error(`${res.data.data}`);
        }

        setspiner(false);
      } catch (err) {
        console.log("error while calling fuction Upgrade", err);
        setspiner(false);
      }
    }
  };

  useEffect(() => {
    // getValue_BNB();
    Getprice();
  }, [amount]);

  useEffect(() => {
    getLiveRate();
    walletConnected();
    TokenBalance();
    Dashboard_API();
  }, []);

  return (
    <>
      <div className="container text-center mx-3">
        <div className="" style={{ marginTop: "20px" }}>
          <Link className="btn btn-danger btn-lg" to="/dashboard">
            Click to Dashboard
          </Link>
        </div>

        <section className="text-center">
          <div>
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div
                      className="modal-header"
                      style={{ paddingBottom: "10px !important" }}
                    >
                      <div className="avlbal">
                        <h2 className="modal-title" id="exampleModal3Label2">
                          Activate 5050 / Upgrade 5050
                        </h2>
                        <h5 className="modal-title" id="exampleModal3Label2">
                          Available Coin Balance :
                          <span id="tokenbalance" style={{ color: "#fff" }}>
                            {balanceUle}
                          </span>
                          <span style={{ color: "#fff" }}> Coin</span>
                        </h5>

                        <h5 className="modal-title" id="exampleModal3Label2">
                          Available BNB Balance :
                          <span
                            style={{ color: "#fff" }}
                            readOnly=""
                            id="getBalance"
                          >
                            {TrxBalance}
                          </span>
                          <span style={{ color: "#fff" }}> BNB</span>
                        </h5>
                        <h5 className="modal-title" id="exampleModal3Label2">
                          Live Rate :
                          <input
                            type="text"
                            className="input_width"
                            id="txtchangevalue"
                            style={{ color: "black" }}
                            placeholder="1 Token= 0.0310 USD  Coin"
                            value={`1 Token= ${liveRate} USD`}
                            readOnly=""
                          />
                        </h5>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="popup_net">
                        <span
                          className="metamaskConnection"
                          style={{ color: "#fff" }}
                        >
                          {connected}
                        </span>
                        {/*-onclick number increase start*/}
                        <div className="addNum">
                          <div className="trxnumber">
                            <input
                              type="text"
                              defaultValue={0}
                              readOnly=""
                              id="txtamount"
                              className="form5"
                              placeholder="Select Package Amount"
                              value={amount}
                            />
                          </div>
                          <br />
                          <div className="trxnumber">
                            <input
                              type="text"
                              readOnly=""
                              id="txtTokenvalue"
                              className="form5"
                              placeholder="Calculated Coin Value"
                              value={valueCoin}
                            />
                            <span>Coin Value</span>
                          </div>
                          <br />
                          <div className="trxnumber">
                            <input
                              type="text"
                              defaultValue=""
                              readOnly=""
                              className="form5"
                              placeholder="Calculated BNB Value"
                              value={valueBNB}
                            />
                            <span>BNB Value</span>
                          </div>
                          <div className="numbtn">
                            <button
                              onClick={() => {
                                fifty(100);
                              }}
                            >
                              + 100 $
                            </button>
                            <button
                              onClick={() => {
                                fifty(200);
                              }}
                            >
                              + 200 $
                            </button>
                            <button
                              onClick={() => {
                                fifty(500);
                              }}
                            >
                              + 500 $
                            </button>
                            <button
                              onClick={() => {
                                fifty(1000);
                              }}
                            >
                              + 1000 $
                            </button>
                            <button
                              onClick={() => {
                                fifty(2500);
                              }}
                            >
                              + 2500$
                            </button>
                          </div>
                          <div className="numbtn">
                            <button
                              onClick={() => {
                                fifty(5000);
                              }}
                            >
                              + 5000$
                            </button>
                            <button
                              onClick={() => {
                                fifty(10000);
                              }}
                            >
                              + 10,000$
                            </button>
                            <button
                              onClick={() => {
                                fifty(15000);
                              }}
                            >
                              + 15,000$
                            </button>
                            <button
                              onClick={() => {
                                fifty(20000);
                              }}
                            >
                              + 20,000$
                            </button>
                            <button
                              onClick={() => {
                                fifty(25000);
                              }}
                            >
                              + 25,000$
                            </button>
                          </div>
                          <input
                            type="hidden"
                            id="packageamount"
                            name="name"
                            autoComplete="off"
                            defaultValue={200}
                          />
                          <div className="joinbtn">
                            <button
                              className="button"
                              onClick={() => {
                                fifty(0);
                              }}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                        {/*-onclick number increase end*/}
                        <h5
                          className="tomenname"
                          style={{ marginTop: "2rem" }}
                        />
                        <div className="row select_network">
                          <div>
                            <div className="col-md-6 col-lg-offset-3">
                              {spiner ? (
                                <>
                                  <div
                                    class="spinner-border spinnerload"
                                    role="status"
                                  >
                                    <span class="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <>

                                 {dash.packageamount > 0 ? (
                                    <>
                                      <button
                                        href="#"
                                        onClick={() => Upgrade()}
                                      >
                                        <div className="text-center net_box ">
                                          <img
                                            src={clicko}
                                            className="img-fluid"
                                          />
                                          <h4>Upgrade</h4>
                                        </div>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        href="#"
                                        onClick={() => Activation()}
                                      >
                                        <div className="text-center net_box ">
                                          <img
                                            src={clicko}
                                            className="img-fluid"
                                          />
                                          <h4>Activate</h4>
                                        </div>
                                      </button>
                                    </>
                                  )}
                                 
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
