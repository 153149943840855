import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import Logo from "../../Assets/images/logo.png";
import Waveone from "../../Assets/images/wave/wave1.png";
import Wavetwo from "../../Assets/images/wave/wave2.png";
import Wavefour from "../../Assets/images/wave/wave4.png";

import { Link, json } from "react-router-dom";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useRef } from "react";
import { API, APILocal } from "../../API/Api";

export default function Dashboard() {
  let UserData = localStorage.getItem("userData");
  let uid = JSON.parse(UserData);
  uid = uid.outuid;
  console.log("Uid", uid);

  const CopyAddressRef = useRef(null);
  const CopyAddressHandler = () => {
    const text = CopyAddressRef.current.value;
    navigator.clipboard.writeText(text);
  };

  const [dash, setdataArray] = useState([]);
  const [user, setUser] = useState(100);
  const [news, setNews] = useState([]);

  const Dashboard_API = async () => {
    try {
      let responce = await API?.get(`GetDashboardDetails?id=${uid}`);
      responce = responce.data.data;
      console.log("responceresponce", responce[0]);
      setdataArray(responce[0]);
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

  const News_API = async () => {
    try {
      let responce = await API?.get(`news`);
      responce = responce.data.data;
      console.log("news", responce);
      if (responce && responce.length > 0) {
        setNews(responce);
      }
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

  useEffect(() => {
    Dashboard_API();
    News_API();
  }, []);

  return (
    <>
      <div className="wrapper">
        <Sidebar />
        <Topbar />
      </div>
      <div>
        <div className="content-page">
          <div className="container-fluid">
            {/* start new dashboard */}
            <div className="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="bg-label-primary rounded-3 text-center mb-3">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="mainss">
                                <div class="text-center">
                                  <div class="text-center ">
                                    <h4 class="mb-0 text-nowrap">
                                      {dash.TotalInvest} Coin
                                    </h4>
                                    <h6 class="userskilTit d-flex justify-content-center">
                                      MY PACKAGE
                                    </h6>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row g-3">
                          <div class="col-12">
                            <div class="userskil">
                              {/* <div class="userskilTit"><span>COMPLETED</span><span>TOTAL</span></div> */}
                              <div class="userskilOne">
                                <div class="userskilFill text-white">
                                  <span class="text-white">
                                    Member ID:- {dash.uid}
                                  </span>
                                </div>
                              </div>
                              <div class="userskilOne text-white">
                                <div class="">
                                  <span class="userskilFill">
                                    <Link
                                      to="/activation"
                                    >
                                      {dash.packageamount > 0 ? (
                                        <>Upgrade 100</>
                                      ) : (
                                        <>Active 100</>
                                      )}
                                    </Link>
                                  </span>
                                  {/* <span class="userskilFill">
                                    <Link
                                      to="/activation5050"
                                    >
                                      {dash.packageamount > 0 ? (
                                        <>Upgrade 5050</>
                                      ) : (
                                        <>Active 5050</>
                                      )}
                                    </Link>
                                  </span> */}
                                </div>
                              </div>

                              <div class="userskilOne">
                                <div className="text-center">
                                  {/* <p className="mb-2">Live rate</p> */}
                                  <p className="mb-2">
                                    Live Rate:<br></br> 1 Token={" "}
                                    {dash.usdperunit} USD
                                  </p>
                                </div>
                              </div>
                              {/* <div class="LegOneSkill">
                                        <h6 class="userskilTit">LEG 1</h6>
                                        <div class="LegOneSkillProgress">
                                            <div class="userskilOne">
                                              <div class="userskilFill text-white"><span class="text-white">400 </span></div>
                                            </div>
                                        </div>
                                      </div>
                                      <div class="LegOneSkill">
                                        <h6 class="userskilTit">LEG 2</h6>
                                        <div class="LegOneSkillProgress">
                                            <div class="userskilOne">
                                              <div class="userskilFill text-white "><span class="">25</span></div>
                                            </div>
                                        </div>
                                      </div>
                                      <div class="LegOneSkill">
                                        <h6 class="userskilTit">OTHERS</h6>
                                        <div class="LegOneSkillProgress">
                                            <div class="userskilOne">
                                              <div class="userskilFill text-white "><span class="">0</span></div>
                                            </div>
                                        </div>
                                      </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <div className="row">
                  <div class="col-md-6">
                    <div class="card">
                      <div class="card-header">
                        <div class="d-flex justify-content-between">
                          <small class="d-block mb-1 colors card-heading ims">
                            My Referral
                          </small>
                          <i class="fa fa-renren ims"></i>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <h5 class="mb-0 pt-1 text-nowrap">
                              {dash.totaldirect}
                            </h5>
                            <small class="text-muted1">Total</small>
                          </div>
                          <div class="col-4">
                            <div class="divider divider-vertical"></div>
                          </div>
                          <div
                            class="col-4 text-right"
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <h5 class="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                              {dash.totaldirectActive}
                            </h5>
                            <small class="text-muted1">Active</small>
                          </div>
                        </div>
                        <div className="iq-progress-bar mt-2">
                          <span
                            className="bg-success iq-progress progress-1"
                            data-percent={75}
                            style={{
                              transition: "width 2s ease 0s",
                              width: "75%",
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="card">
                      <div class="card-header">
                        <div class="d-flex justify-content-between">
                          <small class="d-block mb-1 colors card-heading ims">
                            My Team
                          </small>
                          <i class="fa fa-users ims"></i>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <h5 class="mb-0 pt-1 text-nowrap">
                              {" "}
                              {dash.totaldownline}{" "}
                            </h5>
                            <small class="text-muted1">Total</small>
                          </div>
                          <div class="col-4">
                            <div class="divider divider-vertical"></div>
                          </div>
                          <div
                            class="col-4 text-end "
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <h5 class="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                              {dash.totaldownlineActive}
                            </h5>
                            <small class="text-muted1">Active</small>
                          </div>
                        </div>
                        <div className="iq-progress-bar mt-2">
                          <span
                            className="bg-success iq-progress progress-1"
                            data-percent={75}
                            style={{
                              transition: "width 2s ease 0s",
                              width: "75%",
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <Link to="/LevelIncome">
                      <div class="card">
                        <div class="card-header">
                          <div class="d-flex justify-content-between">
                            <small class="d-block mb-1 colors card-heading ims">
                              Level Income
                            </small>
                            <i class="fa fa-briefcase ims"></i>
                          </div>
                          <div class="row">
                            <div class="col-4">
                              <h5 class="mb-0 pt-1 text-nowrap">
                                {dash.LEVEL_INCOME}
                              </h5>
                              {/* <small class="text-muted1">Total</small> */}
                            </div>
                            <div class="col-4">
                              <div class="divider divider-vertical"></div>
                            </div>
                            <div
                              class="col-4 text-end "
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {/* <h5 class="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">3346</h5>
                                        <small class="text-muted1">Active</small> */}
                            </div>
                          </div>
                          <div className="iq-progress-bar mt-2">
                            <span
                              className="bg-success iq-progress progress-1"
                              data-percent={75}
                              style={{
                                transition: "width 2s ease 0s",
                                width: "75%",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-6">
                    <Link to="#">
                      <div class="card">
                        <div class="card-header">
                          <div class="d-flex justify-content-between">
                            <small class="d-block mb-1 colors card-heading ims">
                              Today Income
                            </small>
                            <i class="fa fa-tachometer ims"></i>
                          </div>
                          <div class="row">
                            <div class="col-4">
                              <h5 class="mb-0 pt-1 text-nowrap">
                                {dash.Today_totalincome}
                              </h5>
                              {/* <small class="text-muted1">Total</small> */}
                            </div>
                            <div class="col-4">
                              <div class="divider divider-vertical"></div>
                            </div>
                            <div
                              class="col-4 text-end "
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {/* <h5 class="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">3346</h5>
                                        <small class="text-muted1">Active</small> */}
                            </div>
                          </div>
                          <div className="iq-progress-bar mt-2">
                            <span
                              className="bg-success iq-progress progress-1"
                              data-percent={75}
                              style={{
                                transition: "width 2s ease 0s",
                                width: "75%",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Link to="/StakeIncome">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex justify-content-between">
                        <small class="d-block mb-1 colors card-heading ims">
                          ROI Income
                        </small>
                        <i class="fa fa-renren ims"></i>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <h5 class="mb-0 pt-1 text-nowrap">
                            {" "}
                            {dash.ROI_INCOME}
                          </h5>
                          {/* <small class="text-muted1">Total</small> */}
                        </div>
                        <div class="col-4">
                          <div class="divider divider-vertical"></div>
                        </div>
                        <div
                          class="col-4 text-right"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <h5 class="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            Coin
                          </h5>
                          {/* <small class="text-muted1">Active</small> */}
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success iq-progress progress-1"
                          data-percent={75}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="/Roi_Level_Income">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex justify-content-between">
                        <small class="d-block mb-1 colors card-heading ims">
                          ROI Level Income
                        </small>
                        <i class="fa fa-rocket ims"></i>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <h5 class="mb-0 pt-1 text-nowrap">
                            {" "}
                            {dash.ROI_LEVEL_INCOME}
                          </h5>
                        </div>
                        <div class="col-4">
                          <div class="divider divider-vertical"></div>
                        </div>
                        <div
                          class="col-4 text-right"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <h5 class="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            Coin
                          </h5>
                          {/* <small class="text-muted1">Active</small> */}
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success2 iq-progress progress-1"
                          data-percent={75}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-4">
                <Link to="/IndirectReward">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex justify-content-between">
                        <small class="d-block mb-1 colors card-heading ims">
                          Reward Income
                        </small>
                        <i class="fa fa-shield ims"></i>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <h5 class="mb-0 pt-1 text-nowrap">
                            {" "}
                            {dash.rewardIncome}
                          </h5>
                        </div>
                        <div class="col-4">
                          <div class="divider divider-vertical"></div>
                        </div>
                        <div
                          class="col-4 text-right"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <h5 class="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            Coin
                          </h5>
                          {/* <small class="text-muted1">Active</small> */}
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success2 iq-progress progress-1"
                          data-percent={75}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="/dashboard">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex justify-content-between">
                        <small class="d-block mb-1 colors card-heading ims">
                          Total Income
                        </small>
                        <i class="fa fa-television ims"></i>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <h5 class="mb-0 pt-1 text-nowrap">
                            {" "}
                            {dash.TOTAL_INCOME}
                          </h5>
                        </div>
                        <div class="col-4">
                          <div class="divider divider-vertical"></div>
                        </div>
                        <div
                          class="col-4 text-right"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <h5 class="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            Coin
                          </h5>
                          {/* <small class="text-muted1">Active</small> */}
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success2 iq-progress progress-1"
                          data-percent={75}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="/WithdrawalHistory">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex justify-content-between">
                        <small class="d-block mb-1 colors card-heading ims">
                          Total Withdrawal
                        </small>
                        <i class="fa fa-shopping-bag ims"></i>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <h5 class="mb-0 pt-1 text-nowrap">
                            {" "}
                            {dash.WithDrawal_Amount}
                          </h5>
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-info iq-progress progress-1"
                          data-percent={85}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "85%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-4">
                <Link to="/Withdrawal">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex justify-content-between">
                        <small class="d-block mb-1 colors card-heading ims">
                          Net Balance
                        </small>
                        <i class="fa fa-paw ims"></i>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <h5 class="mb-0 pt-1 text-nowrap">
                            {" "}
                            {dash.WithDrawal_Net_Balance}
                          </h5>
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-info iq-progress progress-1"
                          data-percent={85}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "85%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-4 ">
                <div className="card1 card-block card-stretch card-height">
                  <div className="card-body">
                    <div className="align-items-center card-total-sale">
                      <div className="icon iq-icon-box-2 bg-info-light">
                        <i className="fa fa-cog" aria-hidden="true" />
                      </div>
                      <div className="line_cost">
                        <p className="mb-2">NFT Wallet</p>
                        <img src={Waveone} alt="" />
                        <p className="mb-2"> {dash.NFTWallet}</p>
                      </div>
                    </div>
                    <div className="iq-progress-bar mt-2">
                      <span
                        className="bg-info iq-progress progress-1"
                        data-percent={85}
                        style={{
                          transition: "width 2s ease 0s",
                          width: "85%",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-8">
                <div className="card-block card-stretch card-height">
                  <div className=" card-header d-flex justify-content-between">
                    <div className="wdg-links  mod_width">
                      <div class="d-flex justify-content-between">
                        <small class="d-block mb-1 colors card-heading ims">
                          Affiliate Link
                        </small>
                      </div>
                      <div className="copy_btn_set">
                        <div className="wdg-box bxset primary">
                          <input
                            type="text"
                            className="wdg-input-box"
                            id="myInput"
                            readOnly=""
                            ref={CopyAddressRef}
                            value={`https://wondercoin.biz/register?referrallink=${dash.uid}`}
                            style={{ fontSize: "2rem" }}
                          />
                          <div className="fast-msg-box" />
                        </div>
                        <div className="wdg-actions copy_btn_set2">
                          {/* <button type="button" onClick="myFunction()"> */}
                          <button type="button" onClick={CopyAddressHandler}>
                            <span
                              style={{ fontSize: 15 }}
                            // onClick="myFunction()"
                            >
                              Copy
                            </span>
                          </button>
                        </div>
                      </div>
                      <div class="mb-2 mt-4">
                        <i class="text-white fa fa-facebook iconss"></i>
                        <i class="text-white fa fa-instagram iconss"></i>
                        <i class="text-white fa fa-telegram iconss"></i>
                        <i class="text-white fa fa-twitter iconss"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Dashboard */}
            <br />
            <div className="row space">
              <div className="col-md-6">
                <div className="">
                  <div className="card card-block card-stretch card-height">
                    <div className="card-header d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Earning Reports</h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="bgbk">
                            <img src="Assets/images/charts.png"></img>
                            <h4 className="imgsss card-title1">
                              {dash.TOTAL_INCOME}{" "}
                            </h4>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <img src="assets/images/logo.png" />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div class="maline align-items-center">
                            <div class="">
                              <div class="d-flex gap-2 align-items-center">
                                <div class="badge rounded bg-label-primary  mts">
                                  <i class="fa fa-dollar"></i>
                                </div>
                                &nbsp;
                                <h6 class="mt-4 text-uppercase">
                                  Level Income{" "}
                                </h6>
                              </div>
                              <div className="mt-2 mb-2">
                                <h5>{dash.LEVEL_INCOME}</h5>
                              </div>
                              <div className="progress h-3 w-100 mt-1 flex-grow-1 mr-4">
                                <div className="progress-bar bg-danger-1 w-65" />
                              </div>
                            </div>

                            <div class="">
                              <div class="d-flex gap-2 align-items-center">
                                <div class="badge rounded bg-label-primary  mts">
                                  <i class="fa fa-dollar"></i>
                                </div>
                                &nbsp;
                                <h6 class="mt-4 text-uppercase">ROI Income</h6>
                              </div>
                              <div className="mt-2 mb-2">
                                <h5> {dash.ROI_INCOME}</h5>
                              </div>
                              <div className="progress h-3 w-100 mt-1 flex-grow-1 mr-4">
                                <div className="progress-bar bg-primary-1 w-80" />
                              </div>
                            </div>
                            <div class="">
                              <div class="d-flex gap-2 align-items-center">
                                <div class="badge rounded bg-label-primary  mts">
                                  <i class="fa fa-dollar"></i>
                                </div>
                                &nbsp;
                                <h6 class="mt-4 text-uppercase">
                                  ROI Level Income
                                </h6>
                              </div>
                              <div className="mt-2 mb-2">
                                <h5> {dash.ROI_LEVEL_INCOME}</h5>
                              </div>
                              <div className="progress h-3 w-100 mt-1 flex-grow-1 mr-4">
                                <div className="progress-bar bg-success w-30" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="">
                  <div className="card card-block card-stretch card-height">
                    <div className="card-header d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Latest News</h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="card-body">
                        <marquee
                          width="100%"
                          direction="up"
                          height="265px;"
                          scrollamount={3}
                        >
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  {/* <p> */}
                                  {news &&
                                    news.map((item, index) => (
                                      <span style={{ fontSize: 26 }}>
                                        {item?.content}
                                      </span>
                                    ))}
                                  {/* </p > */}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </marquee>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row space">
              <div className="container">
                <div className="row row-deck" style={{ marginTop: 15 }}>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="col-md-12">
                      <div className="prgs">
                        {dash.MaxIncome > 90 ? (
                          <img
                            src="/Assets/images/redscoter.png"
                            id="dynamicwidth"
                            style={{ marginLeft: `${dash.MaxIncome}%` }}
                            alt=""
                          />
                        ) : (
                          <img
                            src="/Assets/images/greenscoter.png"
                            id="dynamicwidth"
                            style={{ marginLeft: `${dash.MaxIncome}%` }}
                            alt=""
                          />
                        )}

                        <div id="myProgress">
                          {dash.MaxIncome > 90 ? (
                            <div
                              id="myBar"
                              style={{
                                width: `${dash.MaxIncome}%`,
                                backgroundColor: "red",
                              }}
                            />
                          ) : (
                            <div
                              id="myBar"
                              style={{
                                width: `${dash.MaxIncome}%`,
                                backgroundColor: "green",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className="text_color text-center mt-2"
                        style={{ fontSize: "medium" }}
                      >
                        <span>
                          You have earned Total {dash.MaxIncome} % (
                          {dash.EarnAmount} Coin) out of
                          {dash.CapingPer} % ({dash.tt} Coin) of total
                          investment ({dash.TotalInvest}
                          Coin).
                        </span>
                      </div>
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            '\n    .copy_btn_set{\n        display:flex;\n    }\n    .wdg-box {\n        position: relative;\n        background: rgb(2 6 75);\n        border-radius: 10px;\n        margin-bottom: 2px;\n        color: #fff;\n        width: 100%;\n        border: 1px solid #307758;\n        /*box-shadow: 0 0 1rem #83daf4;*/\n        margin-right: 10px;\n        padding: 0px 2rem;\n    }\n    .wdg-links .wdg-input-box {\n        background: none;\n        border: 0;\n        color: #fff;\n        font: 300 1rem "Cairo",sans-serif;\n        width: 100%;\n    }\n    .wdg-actions {\n        display: flex;\n        /* justify-content: flex-end; */\n        border: 1px solid #307758;\n        /*box-shadow: 0 0 1rem #83daf4;*/\n        border-radius: 10px;\n        background: rgb(2 6 75);\n        padding: 0px 15px;\n    }\n        .wdg-actions button {\n            color: #fff;\n            font: 500 1.2rem "Mukta Vaani",sans-serif;\n            text-transform: uppercase;\n            text-decoration: none;\n            background: none;\n            cursor: pointer;\n            border: 0;\n            display: inline-block;\n            padding-left: 0rem;\n            outline: none;\n        }\n',
                        }}
                      />
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            "\n                                .text_color {\n                                    color: #fff;\n                                    font-size: 18px;\n                                }\n\n                                #myProgress {\n                                    width: 100%;\n                                    background-color: lightblue;\n                                }\n\n                                #myBar {\n                                    width: 1%;\n                                    height: 20px;\n                                }\n                            ",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
          {/* Page end  */}
        </div>
      </div>
      {/* Table Treeview JavaScript */}
      {/* Chart Custom JavaScript */}
      {/* Chart Custom JavaScript */}
      {/* app JavaScript */}
      <svg
        id="SvgjsSvg1001"
        width={2}
        height={0}
        xmlns="http://www.w3.org/2000/svg"
        // version="1.1"
        // xmlnsXlink="http://www.w3.org/1999/xlink"
        // xmlns:svgjs="http://svgjs.com/svgjs"
        style={{
          overflow: "hidden",
          top: "-100%",
          left: "-100%",
          position: "absolute",
          opacity: 0,
        }}
      >
        <defs id="SvgjsDefs1002" />
        <polyline id="SvgjsPolyline1003" points="0,0" />
        <path id="SvgjsPath1004" d="M0 0 " />
      </svg>
    </>
  );
}
