import React from "react";
import "./Landing_Style.css";

export default function Landing_Page() {
  return (
    <div>
      <div className="intro-area intro-area-2">
        <div className="bg-wrapper">
          <img src="Assets/images/bg2.jpg" alt="" />
        </div>
        <div className="intro-content">
          <div className="slider-content">
            <div className="container">
              <div className="row d-flex flex-wrap align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="slide-all-text">
                    {/* layer 2 */}
                    <div
                      className="layer-2 wow fadeInUp"
                      data-wow-delay="0.3s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.3s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <h1 className="title-2">
                        Welcome to the most Sustainable and{" "}
                        <span className="color-text-bold">Profitable </span>
                        Cryptocurrency Platform
                      </h1>
                      <a className="service-btn coin-btn accordion" href="#">
                        Learn More
                      </a>
                      <p className="panel" style={{ display: "none" }}>
                        Wondercoin is a peer-to-peer internet money that allows
                        for immediate, almost-free payments to anyone in the
                        world. A fully decentralized global payment network that
                        is open source and free of any central authorities is
                        called Wondercoin. By enabling people to manage their
                        own finances, mathematics safeguards the
                        network.Wondercoin is more efficient at storing data and
                        confirms transactions more quickly than the top
                        math-based currency.
                      </p>
                    </div>
                    {/* layer 3 */}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="slide-images wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <img src="Assets/images/s1.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-area bg-color-3 fix area-padding" id="about">
        <div className="container">
          <div className="row d-flex flex-wrap align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="about-content">
                <div
                  className="about-images wow fadeInLeft"
                  data-wow-delay="0.7s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.7s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <img
                    src="Assets/images/logos.png"
                    alt=""
                    className="square"
                  />
                  <div className="rotmate-image rotateme">
                    <img src="Assets/images/circle.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="about-all">
                <div className="about-inner">
                  {/* Start about */}
                  <div
                    className="single-about wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <span className="about-icon">01</span>
                    <div className="support-text">
                      <h4>
                        <a href="#">Security</a>
                      </h4>
                      <p>
                        Loaded with security features like Identity and access
                        management, Key management, Data privacy, secure
                        communication, Smart contract security, Transaction
                        endorsement.
                      </p>
                    </div>
                  </div>
                  {/* Start about */}
                  <div
                    className="single-about wow fadeInUp"
                    data-wow-delay="0.5s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.5s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <span className="about-icon">02</span>
                    <div className="support-text">
                      <h4>
                        <a href="#">Community-driven</a>
                      </h4>
                      <p>
                        Decentralized Finance should always provide relevant and
                        easy to use solutions for its users.
                      </p>
                    </div>
                  </div>
                  {/* Start about */}
                  <div
                    className="single-about wow fadeInUp"
                    data-wow-delay="0.7s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.7s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <span className="about-icon">03</span>
                    <div className="support-text">
                      <h4>
                        <a href="#">Fairness</a>
                      </h4>
                      <p>
                        The Wondercoin Platform is owned by its holders. We
                        empower everyone so that their voice can be heard.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* End about */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
