import React from 'react'

export default function Road_Map() {
  return (
    <div>
      <div className="faq-area bg-color-2 area-padding" id="faq">
        <div className="container">
          <div className=" row">
            <div className="col-md-12">
              <div className="section-headline text-center">
                <h2>
                  Wonder <span className="color-text-bold">Roadmap</span>{" "}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8">
              <div className="">
                <img src="Assets/images/roadmap.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
