import "./App.css";
// import Landing_Page from './Components/Landing_page/Landing_Page';
// import Swap from './Components/Swap/Swap';
// import NFT_Market from './Components/NFT_Market/NFT_Market';
// import Gaming from './Components/Gaming/Gaming';
// import Wonder_Wallet from './Components/Wonder_Wallet/Wonder_Wallet';
// import WhatIs_Wonder from './Components/WhatIs_Wonder/WhatIs_Wonder';
// import Road_Map from './Components/Road_Map/Road_Map';
// import Coin_Distribution from './Components/Coin_Distribution/Coin_Distribution';
// import FAQ from './Components/FAQ/FAQ';
// import Contact_US from './Components/Contact_US/Contact_US';
// import Footer from './Components/Footer/Footer';
import { AiOutlineArrowUp } from "react-icons/ai";
// import Header from './Components/Header/Header';
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import LogIn from "./Components/Login/LogIn";
import Register from "./Components/Register/Register";
import Dashboard from "./Components/Dashboard/Dashboard";
import ActivationHistory from "./Components/Pages/ActivationHistory";
import Myreferral from "./Components/Pages/Myreferral";
import Myteam from "./Components/Pages/Myteam";
import Leveldetails from "./Components/Pages/Leveldetails";
import DlBusiness from "./Components/Pages/DlBusiness";
import LevelIncome from "./Components/Pages/All_Income/LevelIncome";
import "react-toastify/dist/ReactToastify.css";
import Roi from "./Components/Pages/All_Income/Roi";
import RoiLevel from "./Components/Pages/All_Income/RoiLevel";
import Reward from "./Components/Pages/All_Income/Reward";
import NotFound from "./Components/NotFound";
import { ToastContainer, toast } from "react-toastify";
import { Toaster } from "react-hot-toast";
import Incomewithdrawal from "./Components/Withdrawal/Incomewithdrawal";
import WithdrawalHistory from "./Components/Withdrawal/WithdrawalHistory";
import Active from "./Components/Upgrade/Active";
import Active5050 from "./Components/Upgrade/Active5050";
import Wallet_Address from "./Components/Wallet_Address/Wallet_Address";
import Contract_Address from "./Components/Wallet_Address/Contract_Address";
import DailyIncome from "./Components/Pages/All_Income/DailyIncome";
import Comingsoon from "./Components/Dashboard/Comingsoon";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/LogIn" element={<LogIn />} />
        <Route path="/register" element={<Register />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/activation" element={<Active />} />
        <Route exact path="/activation5050" element={<Active5050 />} />
        <Route path="/ActivateHistory" element={<ActivationHistory />} />
        <Route path="/Mydirect" element={<Myreferral />} />
        <Route path="/Mydirect" element={<Myreferral />} />
        <Route path="/NewDownline" element={<Myteam />} />
        <Route path="/LevelDetails" element={<Leveldetails />} />
        <Route path="/DirectLegBusiness" element={<DlBusiness />} />
        <Route path="/LevelIncome" element={<LevelIncome />} />
        <Route path="/StakeIncome" element={<Roi />} />
        <Route path="/comingsoon" element={<Comingsoon />} />
        <Route path="/Roi_Level_Income" element={<RoiLevel />} />
        <Route exact path="/IndirectReward" element={<Reward />} />
        <Route exact path="/Withdrawal" element={<Incomewithdrawal />} />
        <Route
          exact
          path="/WithdrawalHistory"
          element={<WithdrawalHistory />}
        />
        <Route exact path="/Wallet_Address" element={<Wallet_Address />} />
        <Route exact path="/Contract_Address" element={<Contract_Address />} />
        <Route path="/DailyIncome" element={<DailyIncome />} />

        <Route path="*" element={<NotFound />} />
      </Routes>

      <a
        id="scrollUp"
        href="#top"
        style={{ position: "fixed", zIndex: 2147483647, display: "block" }}
      >
        <AiOutlineArrowUp style={{ fontSize: "25px" }} />
      </a>
    </div>
  );
}

export default App;
