import React from 'react'
import './NFT_Market.css'

export default function NFT_Market() {
  return (
    <div>
      <div className="swap-area bg-color-3 fix area-padding">
        <div className="container">
          <div className="row d-flex flex-wrap align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="swap-inner">
                <div className="swap-token left-headline">
                  <h2>
                    How to Create
                    <span className="color-text-bold">
                      {" "}
                      NFT Marketplace{" "}
                    </span>{" "}
                  </h2>
                  <p>
                    NFTs allow artist to establish ownership of their digital
                    creations and ensure that they are not duplicated or passed
                    off as someone else's work.In addition to establishing
                    authenticity, NFTs also provide a way to trace the ownership
                    history of a particular digital asset.It's rather rare to
                    sell an NFT for millions. But they do offer artists the
                    chance to sell art online and make it possible for
                    collectors, investors and auctioneers to make a profit.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <img src="Assets/images/nft.png" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
