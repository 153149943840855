import React from "react";
import "./Dashboard.css";
import Logo from "../../Assets/images/logo.png";
import { Link } from "react-router-dom";

export default function Sidebar() {
  return (
    <div className="iq-sidebar  sidebar-default ">
      <div className="iq-sidebar-logo d-flex align-items-center justify-content-between">
        <Link to="/dashboard" className="header-logo">
          <img
            src={Logo}
            className="img-fluid rounded-normal light-logo"
            alt="logo"
          />
        </Link>
        <div className="iq-menu-bt-sidebar ml-0">
          <i className="fa fa-bars wrapper-menu" aria-hidden="true" />
        </div>
      </div>
      <div
        className="data-scrollbar"
        data-scroll={1}
        data-scrollbar="true"
        tabIndex={-1}
        style={{ overflow: "hidden", outline: "none" }}
      >
        <div className="scroll-content">
          <nav className="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" className="iq-menu">
              <li className="active">
                <Link to="/dashboard" className="svg-icon">
                  <svg
                    className="svg-icon"
                    id="p-dash1"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
                    <polyline points="3.27 6.96 12 12.01 20.73 6.96" />
                    <line x1={12} y1="22.08" x2={12} y2={12} />
                  </svg>
                  <span className="ml-4">Dashboard</span>
                </Link>
              </li>

              <li className="">
                <a
                  href="#activation"
                  className="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <svg
                    className="svg-icon"
                    id="p-dash6"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="4 14 10 14 10 20" />
                    <polyline points="20 10 14 10 14 4" />
                    <line x1={14} y1={10} x2={21} y2={3} />
                    <line x1={3} y1={21} x2={10} y2={14} />
                  </svg>
                  <span className="ml-4">Activation</span>
                  <svg
                    className="svg-icon iq-arrow-right arrow-active"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="10 15 15 20 20 15" />
                    <path d="M4 4h7a4 4 0 0 1 4 4v12" />
                  </svg>
                </a>

                <ul
                  id="activation"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li className="">
                    <Link to="/ActivateHistory">
                      <i className="fa fa-money" />
                      <span>Activate History</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className=" ">
                <a
                  href="#category"
                  className="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <svg
                    className="svg-icon"
                    id="p-dash3"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <rect x={9} y={9} width={13} height={13} rx={2} ry={2} />
                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                  </svg>
                  <span className="ml-4">Team Detail</span>
                  <svg
                    className="svg-icon iq-arrow-right arrow-active"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="10 15 15 20 20 15" />
                    <path d="M4 4h7a4 4 0 0 1 4 4v12" />
                  </svg>
                </a>
                <ul
                  id="category"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li className="">
                    <Link to="/Mydirect">
                      <i className="fa fa-life-ring" />
                      <span>My Referral</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/NewDownline">
                      <i className="fa fa-tachometer" />
                      <span>My Team</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/LevelDetails">
                      <i className="fa fa-users" />
                      <span>Level Details</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/DirectLegBusiness">
                      <i className="fa fa-cog" />
                      <span>Direct Leg Business</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className=" ">
                <a
                  href="#sale"
                  className="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <svg
                    className="svg-icon"
                    id="p-dash4"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
                    <path d="M22 12A10 10 0 0 0 12 2v10z" />
                  </svg>
                  <span className="ml-4">All Income</span>
                  <svg
                    className="svg-icon iq-arrow-right arrow-active"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="10 15 15 20 20 15" />
                    <path d="M4 4h7a4 4 0 0 1 4 4v12" />
                  </svg>
                </a>
                <ul
                  id="sale"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li className="">
                    <Link to="/LevelIncome">
                      <i className="fa fa-tasks" />
                      <span>Level Income</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/StakeIncome">
                      <i className="fa fa-suitcase" />
                      <span>ROI Income</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/Roi_Level_Income">
                      <i className="fa fa-tasks" />
                      <span>ROI Level Income</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/IndirectReward">
                      <i className="fa fa-gift" />
                      <span>Reward Income</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/DailyIncome">
                      <i className="fa fa-suitcase" />
                      <span>Daily Income</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className=" ">
                <a
                  href="#return"
                  className="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <svg
                    className="svg-icon"
                    id="p-dash6"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="4 14 10 14 10 20" />
                    <polyline points="20 10 14 10 14 4" />
                    <line x1={14} y1={10} x2={21} y2={3} />
                    <line x1={3} y1={21} x2={10} y2={14} />
                  </svg>
                  <span className="ml-4">Withdrawal</span>
                  <svg
                    className="svg-icon iq-arrow-right arrow-active"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="10 15 15 20 20 15" />
                    <path d="M4 4h7a4 4 0 0 1 4 4v12" />
                  </svg>
                </a>
                <ul
                  id="return"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li className="">
                    <Link to="/Withdrawal">
                      <i className="fa fa-address-card" />
                      <span>Income Withdrawal</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/WithdrawalHistory">
                      <i className="fa fa-money" />
                      <span>Withdrawal History</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="">
                <a href="/" className="">
                  <svg
                    className="svg-icon"
                    id="p-dash7"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1={16} y1={13} x2={8} y2={13} />
                    <line x1={16} y1={17} x2={8} y2={17} />
                    <polyline points="10 9 9 9 8 9" />
                  </svg>
                  <span className="ml-4">Logout</span>
                </a>
                <ul
                  id="reports"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                />
              </li>
              <li className="back_ground">
                {/* <a
                      href="https://bscscan.com/address/0x8002E9A1b6920aAC06F214345cc81723377C82e0"
                      className=""
                      target="_blank"
                    > */}
                <a href="/Contract_Address" className="" target="_blank">
                  <svg
                    className="svg-icon"
                    id="p-dash7"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1={16} y1={13} x2={8} y2={13} />
                    <line x1={16} y1={17} x2={8} y2={17} />
                    <polyline points="10 9 9 9 8 9" />
                  </svg>
                  <span className="ml-4">Contract Address</span>
                </a>
                <ul
                  id="reports"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                />
              </li>
              <li className="back_ground">
                {/* <a
                      href="https://bscscan.com/address/0xFfE7deE80672e14E61e8DE2Eb6CFa450Efc78aaB"
                      className=""
                      target="_blank"
                    > */}
                <a href="/Wallet_Address" className="" target="_blank">
                  <svg
                    className="svg-icon"
                    id="p-dash7"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1={16} y1={13} x2={8} y2={13} />
                    <line x1={16} y1={17} x2={8} y2={17} />
                    <polyline points="10 9 9 9 8 9" />
                  </svg>
                  <span className="ml-4">Wallet Address</span>
                </a>
                <ul
                  id="reports"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                />
              </li>
              <li className="back_ground">
                {/* <a
                      href="https://bscscan.com/address/0xFfE7deE80672e14E61e8DE2Eb6CFa450Efc78aaB"
                      className=""
                      target="_blank"
                    > */}
                <a href="https://wonderfx.info" className="" target="_blank">
                  <svg
                    className="svg-icon"
                    id="p-dash7"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1={16} y1={13} x2={8} y2={13} />
                    <line x1={16} y1={17} x2={8} y2={17} />
                    <polyline points="10 9 9 9 8 9" />
                  </svg>
                  <span className="ml-4">WFT Marketing Plan</span>
                </a>
                <ul
                  id="reports"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                />
              </li>
              <li className="back_ground">
                {/* <a
                      href="https://bscscan.com/address/0xFfE7deE80672e14E61e8DE2Eb6CFa450Efc78aaB"
                      className=""
                      target="_blank"
                    > */}
                <a href="/comingsoon" className="" target="_blank">
                  <svg
                    className="svg-icon"
                    id="p-dash7"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1={16} y1={13} x2={8} y2={13} />
                    <line x1={16} y1={17} x2={8} y2={17} />
                    <polyline points="10 9 9 9 8 9" />
                  </svg>
                  <span className="ml-4">Board of Member</span>
                </a>
                <ul
                  id="reports"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                />
              </li>

              <li className="back_ground">
                {/* <a
                      href="https://bscscan.com/address/0xFfE7deE80672e14E61e8DE2Eb6CFa450Efc78aaB"
                      className=""
                      target="_blank"
                    > */}
                <a href="https://cryptosensex.in/" className="" target="_blank">
                  <svg
                    className="svg-icon"
                    id="p-dash7"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1={16} y1={13} x2={8} y2={13} />
                    <line x1={16} y1={17} x2={8} y2={17} />
                    <polyline points="10 9 9 9 8 9" />
                  </svg>
                  <span className="ml-4">cryptosensex</span>
                </a>
                <ul
                  id="reports"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                />
              </li>

            </ul>
          </nav>
          {/*  <div id="sidebar-bottom" class="position-relative sidebar-bottom">
              <div class="card border-none">
                  <div class="card-body p-0">
                      <div class="sidebarbottom-content">
                          <div class="image"><img src="../assets/images/layouts/side-bkg.png" class="img-fluid" alt="side-bkg"></div>
                          <h6 class="mt-4 px-4 body-title">Get More Feature by Upgrading</h6>
                          <button type="button" class="btn sidebar-bottom-btn mt-4">Go Premium</button>
                      </div>
                  </div>
              </div>
          </div> */}
          <div className="p-3" />
        </div>
        <div
          className="scrollbar-track scrollbar-track-x"
          style={{ display: "none" }}
        >
          <div
            className="scrollbar-thumb scrollbar-thumb-x"
            style={{ width: 260, transform: "translate3d(0px, 0px, 0px)" }}
          />
        </div>
        <div
          className="scrollbar-track scrollbar-track-y"
          style={{ display: "none" }}
        >
          <div
            className="scrollbar-thumb scrollbar-thumb-y"
            style={{ height: 619, transform: "translate3d(0px, 0px, 0px)" }}
          />
        </div>
      </div>
    </div>
  );
}
