import React from 'react'

export default function FAQ() {
  return (
    <div>
      <div className="faq-area bg-color-2 area-padding" id="faq">
        <div className="container">
          <div className=" row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="section-headline text-center">
                <div className="top-text-title">IMPORTANT FAQ</div>
                <h2>
                  Frequently Asked{" "}
                  <span className="color-text-bold">Questions</span>{" "}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Start Column Start */}
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="faq-tab-wrapper">
                <img src="Assets/images/faq.png" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="tab-content">
                {/*Start Tab Content */}
                <div
                  role="tabpanel"
                  className="tab-pane fade in active show"
                  id="tab-1"
                >
                  {/* End Nav tabs */}
                  <div className="company-faq-left">
                    <div className="faq_inner">
                      <div id="accordion">
                        <div className="card">
                          <div className="card-header white-bg" id="headingOne">
                            <h4 className="faq-top-text">
                              <button
                                className="faq-accordion-btn"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                fdprocessedid="9gkbb"
                              >
                                1. What is Wondercoin?
                              </button>
                            </h4>
                          </div>
                          <div
                            id="collapseOne"
                            className="collapse show "
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              Wondercoin is a cutting-edge digital currency that
                              harnesses the power of blockchain technology,
                              offering users a range of features and
                              opportunities in the world of decentralized
                              finance (DeFi).
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header white-bg" id="headingTwo">
                            <h4 className="faq-top-text">
                              <button
                                className="faq-accordion-btn collapsed"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                fdprocessedid="pmugj8"
                              >
                                2. On which blockchain is Wondercoin based?
                              </button>
                            </h4>
                          </div>
                          <div
                            id="collapseTwo"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              Wondercoin is built on the Binance Smart Chain
                              (BEP-20), leveraging its robust infrastructure to
                              provide a secure and efficient environment for
                              token transactions and operations.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header white-bg"
                            id="headingThree"
                          >
                            <h4 className="faq-top-text">
                              <button
                                className="faq-accordion-btn collapsed"
                                data-toggle="collapse"
                                data-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                                fdprocessedid="yznd4"
                              >
                                3. What are the benefits of staking Wondercoins?
                              </button>
                            </h4>
                          </div>
                          <div
                            id="collapseThree"
                            className="collapse"
                            aria-labelledby="headingThree"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              Staking Wondercoins provides several benefits,
                              such as earning passive income through staking
                              rewards, contributing to the stability and
                              security of the network, and having a voice in
                              governance decisions.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header white-bg"
                            id="headingFour"
                          >
                            <h4 className="faq-top-text">
                              <button
                                className="faq-accordion-btn collapsed"
                                data-toggle="collapse"
                                data-target="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseFour"
                                fdprocessedid="r51uv"
                              >
                                4. How can I maximize my returns through the
                                Wondercoin project?
                              </button>
                            </h4>
                          </div>
                          <div
                            id="collapseFour"
                            className="collapse"
                            aria-labelledby="headingFour"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              Wondercoin offers a range of avenues to reap
                              profits and enjoy the associated benefits. By
                              participating in our active referral program, you
                              have the opportunity to earn rewards by
                              introducing new users to the Wondercoin ecosystem.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header white-bg"
                            id="headingFive"
                          >
                            <h4 className="faq-top-text">
                              <button
                                className="faq-accordion-btn collapsed"
                                data-toggle="collapse"
                                data-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                                fdprocessedid="m3kiw"
                              >
                                5. Has Wondercoin been listed on any exchanges?
                              </button>
                            </h4>
                          </div>
                          <div
                            id="collapseFive"
                            className="collapse"
                            aria-labelledby="headingFive"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              Currently, Wondercoin can be acquired directly
                              through our official website, where it can be
                              securely linked to Trust Wallet for storage and
                              management
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End FAQ */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
