import React from "react";

export default function WhatIs_Wonder() {
  return (
    <div>
      <div className="welcome-area bg-color-4 fix area-padding-2" id="service">
        <div className="container">
          <div className="row d-flex flex-wrap align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="row">
                {/* single-services start*/}
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="well-services first-well wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="services-img">
                      <a className="big-icon" href="#">
                        <img src="Assets/images/t1.png" alt="" />
                      </a>
                    </div>
                    <div className="main-wel">
                      <div className="wel-content">
                        <h4>Freedom</h4>
                        <p>
                          Connect your wallet and go and take control of your
                          trading without giving up control of your funds.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="well-services second-well wow fadeInUp"
                    data-wow-delay="0.7s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.7s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="services-img">
                      <a className="big-icon" href="#">
                        <img src="Assets/images/t2.png" alt="" />
                      </a>
                    </div>
                    <div className="main-wel">
                      <div className="wel-content">
                        <h4>Decentralization</h4>
                        <p>
                          True decentralization means community operated
                          validators, security, and decentralized decision
                          power.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single-services start*/}
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="well-services three-well wow fadeInUp"
                    data-wow-delay="0.5s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.5s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="services-img">
                      <a className="big-icon" href="#">
                        <img src="Assets/images/t3.png" alt="" />
                      </a>
                    </div>
                    <div className="main-wel">
                      <div className="wel-content">
                        <h4>High Yield</h4>
                        <p>
                          Get high yield on your staking through our proven
                          business model.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="well-services four-well wow fadeInUp"
                    data-wow-delay="0.5s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.5s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="services-img">
                      <a className="big-icon" href="#">
                        <img src="Assets/images/t4.png" alt="" />
                      </a>
                    </div>
                    <div className="main-wel">
                      <div className="wel-content">
                        <h4>Fairness</h4>
                        <p>
                          The Wondercoin Platform is owned by its holders. We
                          empower everyone so that their voice can be heard.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* single-services End*/}
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="well-service-text ">
                <h2 className="main-title">
                  {" "}
                  <span className="color-text-bold"> What is </span>Wonder?
                </h2>
                <p>
                  Wonderswap is a decenterlize exchange you can swap multiple
                  coins provide liquidity yeild farming and stake make huge
                  profit you get nft open Marketplace on wonderswap you can buy
                  and sell nft here DEXs offer several benifits over centralized
                  exchange. Including enhanced security, privacy, control,
                  transparency,and lower fees.
                </p>
                <a className="service-btn coin-btn" href="#">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
