import React from "react";

export default function Wonder_Wallet() {
  return (
    <div>
      <div className="swap-area bg-color-3 fix area-padding">
        <div className="container">
          <div className="row d-flex flex-wrap align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="swap-inner">
                <div className="swap-token left-headline">
                  <h2>
                    Wonder Dapp{" "}
                    <span className="color-text-bold"> Wallet </span>{" "}
                  </h2>
                  <p>
                    Wonder dapp wallet is user friendly interface Dapps have
                    their backend code (smart contracts) running on a
                    decentralized network and not a centralized server. They use
                    the Ethereum blockchain for data storage and smart contracts
                    for their app logic. A smart contract is like a set of rules
                    that live on-chain for all to see and run exactly according
                    to those rules. Imagine a vending machine: if you supply it
                    with enough funds and the right selection, you'll get the
                    item you want. And like vending machines, smart contracts
                    can hold funds much like your Ethereum account. This allows
                    code to mediate agreements and transactions.
                    <br />
                    Once dapps are deployed on the Ethereum network you can't
                    change them. Dapps can be decentralized because they are
                    controlled by the logic written into the contract, not an
                    individual or a company
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <center>
                <img src="Assets/images/wallet.png" width="100%" />
              </center>
            </div>
          </div>
        </div>
      </div>

      <div className="swap-area bg-color-3 fix area-padding">
        <div className="container">
          <div className="row d-flex flex-wrap align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="swap-inner">
                <div className="swap-token left-headline">
                  <h2>
                    Wonder <span className="color-text-bold"> Land </span>{" "}
                  </h2>
                  <p>
                    Imagine a virtual world where billions of people live, work,
                    shop, learn and interact with each other -- all from the
                    comfort of their couches in the physical world. In this
                    world, the computer screens we use today to connect to a
                    worldwide web of information have become portals to a 3D
                    virtual realm that's palpable -- like real life, only bigger
                    and better. Digital facsimiles of ourselves, or avatars,
                    move freely from one experience to another, taking our
                    identities and our money with us.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <center>
                <img src="Assets/images/meta1.png" width="100%" />
              </center>
            </div>
          </div>
        </div>
      </div>

      <div className="swap-area bg-color-3 fix area-padding">
        <div className="container">
          <div className="row d-flex flex-wrap align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="swap-inner">
                <div className="swap-token left-headline">
                  <h2>
                    What is<span className="color-text-bold"> Crypto? </span>{" "}
                  </h2>
                  <p>
                    Wondercoin is a type of electronic cash that operates
                    without the need for institutions to authenticate
                    transactions. It is based on a distributed public ledger
                    known as the blockchain, which keeps track of all
                    transactions and is updated by currency owners. With the
                    help of this peer-to-peer technology, anyone can send and
                    receive payments from anywhere. Cryptocurrency payments only
                    exist as digital entries to an online database that describe
                    specific transactions, as opposed to being carried around as
                    physical money and used for exchange in the real world. In a
                    public ledger, transactions involving cryptocurrency funds
                    are tracked. Within digital wallets, cryptocurrency is kept.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <img src="Assets/images/1.png" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
