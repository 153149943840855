import React from "react";
import './Footer.css'
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <div>
      <footer className="footer1">
        <div className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="footer-content logo-footer">
                  <div className="footer-head">
                    <div className="footer-logo">
                      <h5>Wonderswap Social Media Link</h5>
                    </div>
                    <div className="footer-icons">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook bg" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-instagram bg" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-telegram bg" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter bg" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-youtube bg" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* end single footer */}
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="footer-content rs-mar-0">
                  <div className="footer-head">
                    <h4>Usefull Links</h4>
                    <ul className="footer-list">
                      <li>
                        <a href="index.html">Home</a>
                      </li>
                      <li>
                        <a href="#about">About</a>
                      </li>
                      <li>
                        <a href="signup.html">Service</a>
                      </li>
                      <li>
                        <a href="signup.html">Token</a>
                      </li>
                      <li>
                        <a href="signup.html">Faq</a>
                      </li>
                    </ul>
                    <ul className="footer-list">
                      <li>
                        <a href="signup.html">Contact</a>
                      </li>
                      <li>
                        <a href="login.html">Login</a>
                      </li>
                      <li>
                        <a href="signup.html">Register</a>
                      </li>
                      <li>
                        <a href="signup.html">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="signup.html">Terms &amp; Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* end single footer */}
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="footer-content last-content rs-mar-0">
                  <div className="footer-head">
                    <h4>About Us</h4>
                    <p>
                      Are you looking for professional advice for your new
                      business. Are you looking for professional advice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Footer Bottom Area */}
        <div className="footer-area-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="copyright">
                  <p>
                    Copyright © 2023
                    <Link to="/">Wondercoin</Link> All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Footer Bottom Area */}
      </footer>
    </div>
  );
}
