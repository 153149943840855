import React from 'react'
import Header from './Components/Header/Header'
import Landing_Page from './Components/Landing_page/Landing_Page'
import Swap from './Components/Swap/Swap'
import NFT_Market from './Components/NFT_Market/NFT_Market'
import Gaming from './Components/Gaming/Gaming'
import Wonder_Wallet from './Components/Wonder_Wallet/Wonder_Wallet'
import WhatIs_Wonder from './Components/WhatIs_Wonder/WhatIs_Wonder'
import Road_Map from './Components/Road_Map/Road_Map'
import Coin_Distribution from './Components/Coin_Distribution/Coin_Distribution'
import FAQ from './Components/FAQ/FAQ'
import Contact_US from './Components/Contact_US/Contact_US'
import Footer from './Components/Footer/Footer'

export default function Home() {
  return (
    <div>
         <Header/>
      <Landing_Page />
      <Swap />
      <NFT_Market/>
      <Gaming/>
      <Wonder_Wallet/>
      <WhatIs_Wonder/>
      <Road_Map/>
      <Coin_Distribution/>
      <FAQ/>
      <Contact_US/>
      <Footer/>
    </div>
  )
}
