import React, { useEffect, useState } from "react";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import { API } from '../../API/Api';
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";

export default function Leveldetails() {

  const [referralApi, setreferralApi] = useState([])
  const [currentPage, setcurrentPage] = useState(1)
  const [listPerpage, setlistPerpage] = useState(10)
  const [Level, setLevel] = useState("")
  const [Status, setStatus] = useState("")
  
  let UserData = localStorage.getItem("userData");
  let uid=JSON.parse(UserData);
   uid = uid.outuid;


  const LevelDetailsAPI = async () => {
    try {
      let res = await API.post(`/levelDetails`, {
        "uid": uid,
        "level": Level,
        "status": Status
      })
      res = res.data.data
      setreferralApi([])
      console.log("LevelDetails", res)
      let arr = []
      res.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          levelcount: item.levelcount,
          date1: `${item.date1}`,
          package: `${item.pp == "" ? '$ 0' : item.pp}`,
          tokenamount: ` $ ${item.tokenamount} `,
          top_update: `${item.top_update} `,
        })
      })

      setreferralApi(arr)
    } catch (e) {
      console.log("Level Details API", e)
    }
  }

  useEffect(() => {
    LevelDetailsAPI()
  }, [])
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: 'S.No', accessor: 'sr' },
      { Header: 'UserID', accessor: 'uid' },
      { Header: 'Level', accessor: 'levelcount' },
      { Header: 'Registration Date', accessor: 'date1' },
      { Header: 'Package', accessor: 'package' },
      { Header: 'Package Coin', accessor: 'tokenamount' },
      { Header: 'Activation Date', accessor: 'top_update' }

    ]
  })

  const indexOfLastPost = currentPage * listPerpage
  const indexOfFirstPage = indexOfLastPost - listPerpage
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost)

  return (
    <>
      <Sidebar />
      <Topbar />
      <div className="content-page  " >
        <div className="grid grid-1">
          <div className="section-heading">
            <h2 className="panel-title">Level Details</h2>
          </div>
          <div className="row" style={{ marginLeft: 10 }}>
            {/* <div className="col-md-2">
              <label className="control-label">Select Level : </label>
            </div> */}
            <div className="col-md-3">
              <select className="form-control" id="level" value={Level} onChange={(e) => setLevel(e.target.value)}>
                <option value="">Select Level</option>
                <option value={0}>All Level</option>
                <option value={1}>Level 1</option>
                <option value={2}>Level 2</option>
                <option value={3}>Level 3</option>
                <option value={4}>Level 4</option>
                <option value={5}>Level 5</option>
                <option value={6}>Level 6</option>
                <option value={7}>Level 7</option>
                <option value={8}>Level 8</option>
                <option value={9}>Level 9</option>
                <option value={10}>Level 10</option>
                <option value={11}>Level 11</option>
                <option value={12}>Level 12</option>
                <option value={13}>Level 13</option>
                <option value={14}>Level 14</option>
                <option value={15}>Level 15</option>
                <option value={16}>Level 16</option>
                <option value={17}>Level 17</option>
                <option value={18}>Level 18</option>
              </select>
            </div>
            {/* <div className="col-md-2">
              <label className="control-label">Choose Status : </label>
            </div> */}
            <div className="col-md-3">
              <select
                className="form-control"
                id="status"
                value={Status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value={2}>All</option>
                <option value={1}>Active</option>
                <option value={0}>In-Active</option>
              </select>
            </div>
            <div className="col-md-3">
              <div className="btn btnSubmit" onClick={() => LevelDetailsAPI()}>Submit</div>
            </div>
          </div>
          <div className="content-wrapper contpdset">
            <div className="grid grid-1">
              <div
                className="box box-default table-wrapper table-responsive"
                style={{ maxHeight: 500, overflow: "scroll" }}
              >

                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                />

              </div>
            </div>
          </div>

          {/*-pagination start*/}
          {/*-pagination end*/}
        </div>
      </div>
    </>
  );
}
