import React, { useState } from 'react'
import './Swap_style.css'
import {TfiLayoutListThumb} from 'react-icons/tfi'
import {BiTime} from 'react-icons/bi'
import {FiSettings} from 'react-icons/fi'
import {AiOutlineArrowDown} from 'react-icons/ai'

export default function Swap() {
  const [nice_select, setnice_select] = useState(false)
  const [token_Select, settoken_Select] = useState(false)
  const [notification, setnotification] = useState(false)
  const [time_Model, settime_Model] = useState(false)
  return (
    <div>
<div className="swap-area bg-color-3 fix area-padding">
  <div className="container">
    <div className="row d-flex flex-wrap align-items-center">
      <div className="col-xl-6 col-lg-6 col-md-6">
        <div className="swap-inner">
          <div className="swap-token left-headline">
            <div className="top-text-title">COIN SWAP</div>
            <h2>
              You can swap <span className="color-text-bold">Wondercoin</span>{" "}
              anytime
            </h2>
            <p>
              Wonderswap is a decenterlize exchange you can swap multiple coins
              provide liquidity yeild farming and stake make huge profit you get
              nft open Marketplace on wonderswap you can buy and sell nft here
              DEXs offer several benifits over centralized exchange. Including
              enhanced security, privacy, control, transparency,and lower fees.
            </p>
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-12">
        {/* Start mony send content */}
        <div className="money-send">
          <div className="calculator-inner">
            <div className="single-cal">
              <div className="swap-top d-flex flex-wrap align-items-center">
                <div className="swap-left">
                  <span>Swap</span>
                </div>
                <div className="swap-right">
                  <ul className="dashboard-right-menus">
                    <li>
                      <a href="#0" className="thumbs" onClick={()=>setnotification(!notification)}>
                        <i  > <TfiLayoutListThumb/></i>
                      </a>
                      <div className={notification ? "notification-area left-part active":"notification-area left-part "} >
                        <div className="notifacation-header">
                          <span className="set-text">
                            <FiSettings/>
                          <span className='mx-2 mt-1'>  Setting</span>
                          </span>
                          <div className="swap-set">
                            <span>Default Transaction Spped</span>
                            <ul>
                              <li>
                                <a href="#">Standard (6)</a>
                              </li>
                              <li>
                                <a href="#">Fast (7)</a>
                              </li>
                              <li>
                                <a href="#">Instant (8)</a>
                              </li>
                            </ul>
                          </div>
                          <div className="swap-set slipege">
                            <span>Slippage Tolerance</span>
                            <ul>
                              <li>
                                <a href="#">0.1%</a>
                              </li>
                              <li>
                                <a href="#">0.5%</a>
                              </li>
                              <li>
                                <a href="#">1%</a>
                              </li>
                              <li>
                                <a href="#">10%</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a href="#0" className="author" onClick={()=>settime_Model(!time_Model)}>
                        <i ><BiTime/></i>
                      </a>
                      <div className={time_Model ? "notification-area side-part active":"notification-area side-part"}>
                        <div className="author-body text-center">
                          <span>
                            Aspernatur sit adipisci quaerat unde at neque Redug
                            Lagre dolorAspernatur sit adipisci quaerat unde at
                            neque Redug Lagre dolor.Aspernatur sit adipisci
                            quaerat unde at neque Redug Lagre dolor
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="inner-form">
                <form action="#">
                  <label>From</label>
                  <input
                    type="number"
                    className="form-input"
                    placeholder={0}
                    fdprocessedid="n6fzb7"
                  />
                  <select style={{ display: "none" }}>
                    <option value="position">BNB</option>
                    <option value="position">USDT</option>
                    <option value="position">ETH</option>
                    <option value="position">BTC</option>
                    <option value="position">BUSD</option>
                  </select>
                  <div className= {nice_select ? "nice-select open":"nice-select "} tabIndex={0} onClick={()=>setnice_select(!nice_select)}>
                    <span className="current">BNB</span>
                    <ul className="list">
                      <li data-value="position" className="option selected">
                        BNB
                      </li>
                      <li data-value="position" className="option">
                        USDT
                      </li>
                      <li data-value="position" className="option">
                        ETH
                      </li>
                      <li data-value="position" className="option">
                        BTC
                      </li>
                      <li data-value="position" className="option">
                        BUSD
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
              <div className="middle-inti text-center">
                <a href="#">
                  <AiOutlineArrowDown  style={{fontSize:"30px",marginTop:"-10px"}} />
                </a>
              </div>
              <div className="inner-form">
                <form action="#">
                  <label>To</label>
                  <input
                    type="number"
                    className="form-input"
                    placeholder={0}
                    fdprocessedid="qdgjkbk"
                  />
                  <select style={{ display: "none" }}>
                    <option value="position">WNC</option>
                    <option value="position">BNB</option>
                    <option value="position">USDT</option>
                    <option value="position">ETH</option>
                    <option value="position">BTC</option>
                  </select>
                  <div className= {token_Select ? "nice-select open":"nice-select"} tabIndex={0} onClick={()=>settoken_Select(!token_Select)}>
                    <span className="current">WNC</span>
                    <ul className="list">
                      <li data-value="position" className="option selected">
                        CST
                      </li>
                      <li data-value="position" className="option">
                        BNB
                      </li>
                      <li data-value="position" className="option">
                        USDT
                      </li>
                      <li data-value="position" className="option">
                        ETH
                      </li>
                      <li data-value="position" className="option">
                        BTC
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
              <div className="inner-form-text">
                <div className="rate-text d-flex flex-wrap align-items-center">
                  <span>Slippage Tolerance</span>
                  <span>Auto</span>
                </div>
              </div>
              <button className="cale-btn" fdprocessedid="7fvti">
                Unlock Wallet
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    </div>
  )
}
