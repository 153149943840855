import React from 'react'

export default function Coin_Distribution() {
  return (
    <div>
      <div className="information-area bg-color fix area-padding-2" id="token">
        <div className="container">
          <div className=" row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="section-headline text-center">
                <div className="top-text-title">Wonder INFORMATION</div>
                <h2>
                  Our Coin <span className="color-text-bold">Distribution</span>{" "}
                </h2>
              </div>
            </div>
          </div>
          <div className="row d-flex flex-wrap align-items-center">
            {/* Start column  */}
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="price-box-inner">
                <h5>Supply &amp; Price Information</h5>
                <div className="table-responsive">
                  <table className="table table-borderless text-white tableInfo">
                    <tbody>
                      <tr>
                        <td>Staking &amp; Rewards</td>
                        <td>:</td>
                        <td>50%</td>
                      </tr>
                      <tr>
                        <td>Team</td>
                        <td>:</td>
                        <td>15%</td>
                      </tr>
                      <tr>
                        <td>Marketing &amp; Development</td>
                        <td>:</td>
                        <td>10%</td>
                      </tr>
                      <tr>
                        <td>Public Sale</td>
                        <td>:</td>
                        <td>10%</td>
                      </tr>
                      <tr>
                        <td>Private Sale</td>
                        <td>:</td>
                        <td>10%</td>
                      </tr>
                      <tr>
                        <td>Foundation</td>
                        <td>:</td>
                        <td>5%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* End column  */}
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="info-images">
                <h4>Total Supply:- 110,000,000</h4>
                <img src="Assets/images/token.png" />
                <br />
                <p style={{ fontSize: 12 }}>
                  Note: 70% token will be burn from staking and Reward.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
