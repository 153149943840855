import React from "react";

export default function Contact_US() {
  return (
    <div>
      <div className="contact-page bg-color area-padding-3" id="contact">
        <div className="container">
          <div className=" row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="section-headline text-center">
                <div className="top-text-title">Get In Touch</div>
                <h2>
                  Contact<span className="color-text-bold">Us</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="contact-head">
                <h4>Request a contact us</h4>
                <p>
                  Revail The universal acceptance of building has given a
                  tremendous opportunity for merchants to do crossborder
                  transactions instantly and at reduced cost.
                </p>
                <div className="single-contact">
                  <a href="#">
                    <i className="fa fa-envelope" />
                    <span>info@wondercoin.biz</span>
                  </a>
                </div>
                <div className="project-share">
                  <h5>Wondercoin Social Media Link</h5>
                  <ul className="project-social">
                    <li>
                      <a href="" target="_blank">
                        <i className="fa fa-facebook bg" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/wondercoin.biz6/"
                        target="_blank"
                      >
                        <i className="fa fa-instagram bg" />
                      </a>
                    </li>
                    <li>
                      <a href="https://t.me/wondercoinofficial" target="_blank">
                        <i className="fa fa-telegram bg" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/wondercoin35375?t=LTkI_StzvlTxGCth85mOGA&s=09"
                        target="_blank"
                      >
                        <i className="fa fa-twitter bg" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa fa-youtube bg" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* End contact icon */}
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="contact-form">
                <form
                  id="contactForm"
                  method="POST"
                  action="contact.php"
                  className="contact-form"
                >
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        placeholder="Name"
                        required=""
                        data-error="Please enter your name"
                        fdprocessedid="ojpphs"
                      />
                      <div className="help-block with-errors" />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <input
                        type="email"
                        className="email form-control"
                        id="email"
                        placeholder="Email"
                        required=""
                        data-error="Please enter your email"
                        fdprocessedid="t1d4u"
                      />
                      <div className="help-block with-errors" />
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <input
                        type="text"
                        id="msg_subject"
                        className="form-control"
                        placeholder="Subject"
                        required=""
                        data-error="Please enter your message subject"
                        fdprocessedid="vehet"
                      />
                      <div className="help-block with-errors" />
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <textarea
                        id="message"
                        rows={7}
                        placeholder="Massage"
                        className="form-control"
                        required=""
                        data-error="Write your message"
                        defaultValue={""}
                      />
                      <div className="help-block with-errors" />
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <button
                        type="submit"
                        id="submit"
                        className="ready-btn contact-btn coin-btn"
                        fdprocessedid="pv8vs"
                      >
                        Submit
                      </button>
                      <div id="msgSubmit" className="h3 text-center hidden" />
                      <div className="clearfix" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* End contact Form */}
          </div>
        </div>
      </div>
    </div>
  );
}
