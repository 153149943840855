import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { API, APILocal } from "../../API/Api";
import { loadWeb3 } from "../../API/contract";
import "./IncomeWithdrawal.css";
import Sidebar from "../../Components/Dashboard/Sidebar";
import Topbar from "../../Components/Dashboard/Topbar";

export default function Incomewithdrawal() {
  const [LAR_live, setLAR_live] = useState("");
  const [get_Value, setget_Value] = useState("");
  const [Connect, setConnect] = useState("Wallet is not connected..!..Wait...");
  const [spinner, setspinner] = useState(false);
  let [loading, setLoading] = useState(false);
  const [TokenAmount, setTokenAmount] = useState("0");
  const [Dash_Board_Array, setDash_Board_Array] = useState("");
  const [WalletAddress, setWalletAddress] = useState("")
  

  let UserData = localStorage.getItem("userData");
  let uid = JSON.parse(UserData);
  uid = uid.outuid;

  console.log("WalletAddress",WalletAddress);

  const Dashboard_API = async () => {
    try {
      let responce = await API?.get(`GetDashboardDetails?id=${uid}`);
      responce = responce.data.data;
      console.log("responceresponce", responce[0]);
      setDash_Board_Array(responce[0]);
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

  const Connect_Wallet = async () => {
    try {
    let acc= await loadWeb3();
    setWalletAddress(acc);
    console.log("acc",acc);
    
      if (acc == "No Wallet") {
        setConnect("No Wallet Connected");
        // toast.error("No Wallet Connected")
      } else if (acc == "Wrong Network") {
        setConnect(
          "Wrong Newtwork please connect to Binance smart chain network"
        );

        // toast.error("Wrong Newtwork please connect to Binance smart chain network")
      } else {
        setConnect("Wallet is connected..!");
      }
    } catch (e) {
      console.log("Error While connect Wallet", e);
    }
  };

  const Withdrawal_contract = async () => {
    try {

      // setspinner(true);
      let acc=WalletAddress ;// await loadWeb3();
      console.log("acc",acc);

      if (acc == "No Wallet") {
        toast.error("No Wallet Connected");
        // setspinner(false);
      } else if (acc == "Wrong Network") {
        // setspinner(false);
        toast.error(
          "Wrong Newtwork please connect to Binance smart chain network"
        );
      } else {
        console.log("Withdrawal_contract");

        if (Dash_Board_Array.address == acc) {
     
          // save data in db
          let saveResult = await API.post("/withdrawal", {
            uid: uid,
            address: acc,
            amount: TokenAmount,
            txn: "",
          });
          console.log(saveResult);
          saveResult = saveResult.data.data;
          // setspinner(false);
          if (saveResult == "SUCCESSFUL") {
            toast.success(saveResult);
          } else {
            toast.error(saveResult);
          }
        } else {
          toast.error("Address MissMatch");
          // setspinner(false);
        }
      }
    } catch (e) {
      console.log("Error While Calling MultiToken ", e);
      // setspinner(false);
    }
  };

  useEffect(async () => {
    Dashboard_API()
    Connect_Wallet();
  }, [])

  return (
    <>
      <Sidebar />
      <Topbar />
      <div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-6" style={{ top: "10rem" }}>
            <div className="modal-dialog">
              <div className="modal-content exchange_width">
                <div className="modal-header exchange_hd">
                  <h1>Withdrawal</h1>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="panel-body">
                      <span id="metamaskConnection">
                        {Connect}
                      </span>
                      <br />
                     
                      <div className="row">
                        <div className="col-md-5">
                          <label>Wallet Net Token Value</label>
                        </div>
                        <div className="col-md-6">
                          <label
                            className="form-control"
                          >
                            {Dash_Board_Array.WithDrawal_Net_Balance}
                          </label>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-5">
                          <label>Enter Token Amount </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control "
                            id="amount"
                            placeholder="Enter Token Amount"
                            oninput="return numberValidation(this);"
                            maxLength={10}
                            onChange={(e) => {
                              setTokenAmount(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-5">
                          <label>Admin Charge(5%) </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control mb-20"
                            id="adminchargeamount"
                            oninput="return numberValidation(this);"
                            maxLength={10}
                            readOnly=""
                            value={TokenAmount *0.05}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-5">
                          <label>NFT Charge(5%) </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control mb-20"
                            oninput="return numberValidation(this);"
                            maxLength={10}
                            readOnly=""
                            value={TokenAmount *0.05}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-5">
                          <label>Net Payable Amount </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control mb-20"
                            id="netpayableamount"
                            oninput="return numberValidation(this);"
                            maxLength={10}
                            readOnly=""
                            value={TokenAmount *0.90}
                          />
                        </div>
                      </div>
                      <br />
                      <div
                        className="row mrset"
                        id="withdrwaltokendiv"
                        style={{ display: "none" }}
                      >
                        <div className="col-md-5">
                          <label>Withdrwal Token</label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            id="tokenvalue1"
                            placeholder="Withdrwal Token "
                            disabled=""
                          />
                        </div>
                        <div className="col-md-4" style={{ display: "none" }}>
                          <input
                            type="text"
                            className="form-control "
                            id="tokenvalue"
                            placeholder="Withdrwal BNB "
                            disabled=""
                          />
                        </div>
                      </div>
                      <input
                        type="hidden"
                        id="address"
                        className="form-control"
                        defaultValue="0xFfE7deE80672e14E61e8DE2Eb6CFa450Efc78aaB"
                        autoComplete="off"
                      />
                      <input
                        type="hidden"
                        id="userid"
                        className="form-control "
                        defaultValue={100}
                        autoComplete="off"
                      />
                      <div className="container">
                        <div className="row">
                          <div
                            className="col-md-4 col-md-offset-5 "
                            id="divsubmit"
                          >
                            <button
                              className="btn btn-success btn-lg"
                              type="submit"
                              onclick="return withdrawAmount_v1();"
                              style={{ marginTop: 10 }}
                              id="btnother"
                              onClick={()=>{Withdrawal_contract()}}
                            >
                              Withdrawal
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

