import React, { useState } from "react";
import "../Login/logIn_style.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { loadWeb3 } from "../../API/contract";
import { useSearchParams } from "react-router-dom";
import { API, APILocal } from "../../API/Api";
import { useEffect } from "react";
import axios from "axios";

export default function Register() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sponser, setSponser] = useState(searchParams.get("referrallink"));
  let navigate = useNavigate();
  const [registor_Model, setregistor_Model] = useState(false);
  const [connected, setconnected] = useState(true);
  const [sid, setSid] = useState(sponser);
  const [walletAddress, setWalletAddress] = useState("");

  console.log("sid", sid);

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        // toast.error("No Wallet");
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        let response = await axios.get("https://api64.ipify.org/?format=json");
        console.log("response Geolocation", response.data.ip);
        // setIP(response.data.IPv4)
        response = response.data.ip;
        let res = await API.post(`/userlogin`, {
          userid: acc,
          ipaddress: response,
        });
        console.log("UserloginNew", res);
        let UserID = res.data.data;
        if (res.data.data.result != null) {
          toast.success(`Login Successful`);
          // dispatch(updateAuth({ isAuth: true, userId: data.uid }));
          localStorage.setItem("userData", JSON.stringify(UserID));

          navigate("/dashboard");
          window.location.reload();
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const registerUser = async () => {
    let acc = await loadWeb3();
    if (acc == "No Wallet") {
      toast.error("No Wallet");
    } else if (acc == "Wrong Network") {
      toast.error("Wrong Network Please Connect BSC Scan Network");
    } else {
      try {
        let res = await API.post("registration", {
          sid: sid,
          walletAddress: acc,
          sidAddress: acc,
        });
        console.log("registration", res.data);
        if (res.data.data.result == "Successfull") {
          toast.success("Register Successfull");

          let response = await axios.get("https://api64.ipify.org/?format=json");
          console.log("response Geolocation", response.data.ip);
          // setIP(response.data.IPv4)
          response = response.data.ip;
          let res = await API.post(`/userlogin`, {
            userid: acc,
            ipaddress: response,
          });
          console.log("UserloginNew", res);
          let UserID = res.data.data;
          if (res.data.data.result != null) {
            toast.success(`Login Successful`);
            // dispatch(updateAuth({ isAuth: true, userId: data.uid }));
            localStorage.setItem("userData", JSON.stringify(UserID));

            navigate("/dashboard");
            window.location.reload();
          } else {
            toast.error(`Invalid User id or Address`);
            // setspinnerload(false);
          }

          //setspiner(false);
        } else {
          toast.error(`${res.data.data.result}`);
          //setspiner(true);
        }
      } catch (e) {
        console.log("Error while regiater", e);
      }
    }
  };

  useEffect(() => {
    walletConnected();
  });

  return (
    <div className="Login_bg">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3"></div>
            <div className="col-md-6">
              <div id="root">
                <div id="reg-layout">
                  <div className="Toastify" />
                  <div className="reg-box">
                    <center
                      style={{ marginBottom: 20, marginTop: "5rem" }}
                    ></center>
                    <a href="/">
                      <div className="reg-label" />
                    </a>
                    <div className="reg-content">
                      <div className="space_set" />
                      <div className="subtitle">
                        Access your personal account
                      </div>
                      <span style={{ display: "none" }} id="lock" />
                      <span style={{ display: "none" }} id="network">
                        This is the unknown test network.
                      </span>
                      {connected == ("No Wallet" || "Wrong Network") ? (
                        <>
                          <span
                            id="metamaskConnection"
                            className="metamaskConnection"
                            style={{ color: "red" }}
                          >
                            Wallet is not Connected
                          </span>
                        </>
                      ) : (
                        <span
                          id="metamaskConnection"
                          className="metamaskConnection"
                          style={{ color: "green" }}
                        >
                          {connected}
                        </span>
                      )}

                      <br />
                      <button
                        className="btn btn-primary set_z_index"
                        onClick={() => setregistor_Model(true)}
                      >
                        Register
                      </button>

                      <p className="note">
                        Remember to authorize with the correct Wallet address.
                      </p>
                      <div className="space_set" />
                      <div className="subtitle">View an Account</div>
                      <br />
                      <form>
                        <div className="form-row">
                          <input
                            id="loginid"
                            type="text"
                            maxLength={100}
                            oninput="return nameNumValidation(this);"
                            placeholder="Please enter ID or address "
                            className="form-input"
                          />
                          <p className="note">
                            Enter the account id or address
                          </p>
                        </div>
                        <div
                          className="form-row"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <input
                            type="button"
                            className="btn btn-secondary"
                            id="btnlogins"
                            defaultValue="Connect to Wallet"
                            onclick="idoraddresslogin()"
                          />
                        </div>
                      </form>
                      <p className="loginagain">
                        If you have already registered then{" "}
                        <Link to="/login" style={{ color: "#ff06b7" }}>
                          Login
                        </Link>
                      </p>
                      <div className="footer-section2">
                        {/* Copyright © 2023 wondercoin. All Rights Reserved. */}
                        <div className="copyright">
                          Copyright © 2023{" "}
                          <Link to="/">Wondercoin</Link>. All Rights Reserved.
                        </div>
                      </div>
                      <div
                        id="myModal"
                        className="modal"
                        style={{ display: registor_Model ? "block" : "none" }}
                      >
                        <div className="modal-content-register boxset">
                          <h4>Enter Upline ID</h4>
                          <br />
                          <p>
                            {" "}
                            <input
                              id="txtsid"
                              className="input-id"
                              value={sid}
                              maxLength={50}
                              type="number"
                              min={0}
                              onChange={(e) => {
                                setSid(e.target.value);
                              }}
                            />
                          </p>
                          <br />
                          <div className="rerlbtn">
                            <button
                              className=" btn_Model"
                              onClick={() => {
                                registerUser();
                              }}
                            >
                              Register
                            </button>

                            <button
                              className="mx-2 btn_Model"
                              onClick={() => setregistor_Model(false)}
                            >
                              close
                            </button>
                          </div>
                        </div>
                      </div>
                      <div id="myModal123" className="modal1 d-none">
                        <div className="modal-content boxset">
                          <h4>Referral Confirmation</h4>
                          <p>
                            Your Current Referral ID is <label>100</label>
                          </p>
                          <br />
                          <br />
                          <div className="rerlbtn">
                            <button
                              className="btn btn-primary"
                              onclick="register()"
                            >
                              Proceed
                            </button>
                            <a href="/registration" className="btn btn-primary">
                              No,I want to change ID
                            </a>
                          </div>
                        </div>
                      </div>
                      <center>
                        <br />
                        <Link
                          to="/"
                          title="Go To Home"
                          id="gotohome"
                          style={{ color: "#ff06b7" }}
                        >
                          Go To Home
                        </Link>
                      </center>
                      {/*--modal popup end*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
