import React, { useState } from "react";
import Logo from "../../Assets/images/logo.png";
import './Header_style.css'
import { Link } from "react-router-dom";

export default function Header() {
    const [nav_open, setnav_open] = useState(false)
  return (
    <div>
      <header className="header-one">
        <div id="sticker" className="header-menu-area header-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-3 d-flex align-items-center">
                <div className="logo">
                  <a href="/">
                    <img src={Logo} />
                  </a>
                </div>
              </div>
              <div className="col-xl-10 col-lg-10 col-md-9 disktop">
                {/*  <div class="header-right ">
                       <a href="token-ico.html" class="top-btn coin-btn">Buy token</a>
                   </div> */}
                <div className="header_menu f-right">
                  <nav id="mobile-menu" style={{ display: "block" }}>
                    <ul className="main-menu">
                      <li className="resulta">
                        <a href="/">Home</a>
                      </li>
                      <li className="resulta">
                        <a href="#about">About us</a>
                      </li>
                      <li className="resulta">
                        <a href="#service">Service</a>
                      </li>
                      <li className="resulta">
                        <a href="#token">Token</a>
                      </li>
                      <li className="resulta">
                        <a href="#faq">Faq</a>
                      </li>
                      <li className="contact">
                        <a href="#contact">Contact us</a>
                      </li>
                      <li className="contact">
                        <Link to="/LogIn">Login</Link>
                      </li>
                      <li className="contact">
                        <Link to="/register">Register</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-12 mobile">
                <div className="mobile-menu mean-container">
                  <div className="mean-bar">
                    <a
                      onClick={() => setnav_open(!nav_open)}
                      className="meanmenu-reveal"
                      style={{
                        background: "",
                        color: "",
                        right: 0,
                        left: "auto",
                      }}
                    >
                      <span />
                      <span />
                      <span />
                    </a>
                    <nav className="mean-nav">
                      <ul
                        className="main-menu"
                        style={{ display: nav_open ? "block" : "none" }}
                      >
                        <li className="resulta">
                          <a href="/">Home</a>
                        </li>
                        <li className="resulta">
                          <a href="#about">About us</a>
                        </li>
                        <li className="resulta">
                          <a href="#service">Service</a>
                        </li>
                        <li className="resulta">
                          <a href="#token">Token</a>
                        </li>
                        <li className="resulta">
                          <a href="#faq">Faq</a>
                        </li>
                        <li className="contact">
                          <a href="#contact">Contact us</a>
                        </li>
                        <li className="contact">
                          <Link to="/LogIn">Login</Link>
                        </li>
                        <li className="contact mean-last">
                          <Link to="/register">Register</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
