import React from 'react'

export default function Gaming() {
  return (
    <div>
      <div className="feature-area bg-color fix area-padding">
        <div className="container">
          <div className="row d-flex flex-wrap align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="feature-inner">
                <div className="left-headline">
                  <h2>
                    Wonder Gaming Platform -{" "}
                    <span className="color-text-bold">launching Soon </span>
                  </h2>
                  <p>
                    Wonder Game is an online crypto casino and gaming platform
                    that allows players to enjoy a wide range of games using
                    various cryptocurrencies such as Bitcoin, Ethereum, and
                    more. The platform offers a diverse selection of games
                    including slots, table games, live dealer games, and sports
                    betting. Wonder Game is known for its provably fair gaming
                    system, which ensures that all games are fair and
                    transparent
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="feature-content">
                <div className="feature-images">
                  <img src="Assets/images/gam.png" width="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
