import React, { useEffect, useState } from "react";
import Sidebar from "../../Dashboard/Sidebar";
import Topbar from "../../Dashboard/Topbar";
import { API } from "../../../API/Api";
import Table from "../../Table/Table";
import Table_Buttons from "../../Table_Buttons/Table_Button";

export default function Reward() {
  const [referralApi, setreferralApi] = useState([])
  const [currentPage, setcurrentPage] = useState(1)
  const [listPerpage, setlistPerpage] = useState(10)
  
  let UserData = localStorage.getItem("userData");
  let uid=JSON.parse(UserData);
   uid = uid.outuid;

  const LevelDetailsAPI = async () => {
    try {
      let res = await API.get(`/RewardIncome?id=${uid}`)
      res = res.data.data
      setreferralApi([])
      console.log("LevelDetails", res)
      let arr = []
      res.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          token_amount: item.token_amount,
          rank_name: `${item.rank_name}`,
          lPV: `${item.lPV}`,
          lCount: ` ${item.lCount} `,
          oncondition: ` ${item.oncondition} `,
          ac: ` ${item.ac} --> ${item.achieveddate}`,
          pc: ` ${item.ps} --> ${item.paiddate}`,
        })
      })

      setreferralApi(arr)
    } catch (e) {
      console.log("Level Details API", e)
    }
  }

  useEffect(() => {
    LevelDetailsAPI()
  }, [])
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: 'S.No', accessor: 'sr' },
      { Header: 'Reward', accessor: 'rank_name' },
      { Header: 'Business', accessor: 'lPV' },
      { Header: 'Downline', accessor: 'lCount' },
      { Header: 'Direct', accessor: 'oncondition' },
      { Header: 'Income(Coin)', accessor: 'token_amount' },
      { Header: 'Achieved Status', accessor: 'ac' },
      { Header: 'Paid Status', accessor: 'pc' }

    ]
  })

  const indexOfLastPost = currentPage * listPerpage
  const indexOfFirstPage = indexOfLastPost - listPerpage
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost)


  return (
    <>
      <Sidebar />
      <Topbar />
      <div className="content-page" >
        <div className="grid grid-1">
          <div className="section-heading">
            <h2 className="panel-title">Reward</h2>
          </div>
          <div className="content-wrapper contpdset">
            <div className="grid grid-1">
              <div
                className="box box-default table-wrapper table-responsive"
                style={{ maxHeight: 500, overflow: "scroll" }}
              >

                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                />

              </div>
            </div>
          </div>

          {/*-pagination start*/}
          {/*-pagination end*/}
        </div>
      </div>
    </>
  );
}
